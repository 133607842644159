export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Staff Name',
        key: 'StaffName',
        sortable: true,
        minWidth: 100,
    },
	{
        title: 'From Date',
        key: 'DateFrom',
		sortable: true,
        minWidth: 100,
    },    
    {
        title: 'To Date',
        key: 'DateTo',
        sortable: true,
        minWidth: 100,
    },
    {
        title: 'Shift',
        key: 'Shift',
        sortable: true,
        minWidth: 100,
    },
    {
        title: 'Notes',
        key: 'ShortNotes',
        sortable: true,
        minWidth: 100,
    },
]
